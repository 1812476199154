<template>
  <div>
    <el-row class="row_bottom"
    >
      <el-col :md="{ span: 5 }">
        <h2>评论</h2>
      </el-col>
    </el-row>
    <el-row class="row_bottom" v-if="$store.state.user.user.username" :gutter="50">
      <el-col :md="{ span: 1 }" :xs="1">
        <el-avatar
          size="large"
          :src="$store.state.user.user.avatar"
        ></el-avatar>
      </el-col>
      <el-col :md="{ span: 22 }" :xs="20">
        <el-row :gutter="10">
          <el-col :md="{ span: 22 }" :xs="20">
            <el-input
              type="textarea"
              placeholder="发表你的评论"
              resize="none"
              rows="2"
              v-model="commentJson.comment"
            ></el-input>
          </el-col>
          <el-col :md="{ span: 2 }":xs="4">
            <el-button class="comment_current_button" @click="comment('commentLoading')"
            :loading="loadingObj['commentLoading']"
              >发表</el-button
            >
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <template v-if="commentList" v-for="(commentItem, index) in commentList">
      <el-row class="row_bottom" :gutter="50">
        <el-col :md="{ span: 1 }" :xs="1">
          <div style="cursor: pointer;"
          @click="link_to_user(commentItem.user.username)"
          >
          <el-avatar
            size="large"
            :src="commentItem.user.avatar"
          ></el-avatar>
          </div>
        </el-col>
        <el-col :md="{ span: 22 }" :xs="20">
          <el-row class="name_comment_bottom">
            <el-col :md="{ span: 2 }" :xs="6">
              <span class="comment_username"
              style=""
              >{{
                commentItem.user.nickname
              }}</span>
            </el-col>
            <el-col :md="{ span: 6 }":xs="11">
              <span class="comment_create_time"
                >{{ formatTime(new Date(commentItem.createTime).getTime(),'{y}年{m}月{d}日') }}</span
              >
            </el-col>
            <el-col
              :md="{ span: 5 }"
              :xs="5"
              v-if="$store.state.user.user.username == commentItem.user.username"
            >
              <button
                class="blog_operate_delete_button"
                @click="deleteComment(commentItem.id, index)"
              >
                删除
              </button>
            </el-col>
          </el-row>
          <el-row class="name_comment_bottom">
            <el-col :md="{ span: 24 }">
              <span>{{ commentItem.comment }}</span>
            </el-col>
          </el-row>
          <el-row class="name_comment_bottom">
            <el-col :md="{span:5}" :xs="16">
              <PraiseStep :item="commentItem"
              itemType="comment" :showGlance="false"></PraiseStep>
            </el-col>
            <el-col :md="{ span: 12 }":xs="8">
              <button
                class="blog_operate_botton"
                @click="handleOpenReply(null, commentItem)"
              >
                回复
              </button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <template v-for="(replyCommentItem,replyIndex) in commentItem.replyCommentList">
        <el-row class="reply_row_bottom" :gutter="30">
          <el-col :md="{ span: 1 }" :xs="1">
            <div style="cursor: pointer;"
            @click="link_to_user(replyCommentItem.user.username)"
            >
            <el-avatar
              size="small"
              :src="replyCommentItem.user.avatar"
            ></el-avatar>
            </div>
          </el-col>
          <el-col :md="{ span: 22 }":xs="20">
            <el-row class="name_replay_comment_bottom" :gutter="5">
              <el-col :md="{ span: 2 }" :xs="6">
                <span class="reply_comment_username">{{
                  replyCommentItem.user.nickname
                }}</span>
              </el-col>
              <el-col :md="{ span: 6 }":xs="13">
                <span class="reply_comment_create_time"
                  >{{ formatTime(new Date(replyCommentItem.createTime).getTime(),'{y}年{m}月{d}日')  }}</span
                >
              </el-col>
              <el-col
                :md="{ span: 1 }"
                :xs="0"
                v-if="
                  $store.state.user.user.username === replyCommentItem.user.username
                "
              >
                <button
                  class="blog_operate_delete_button"
                  @click="deleteReplyComment(replyCommentItem.id, index)"
                >
                  删除
                </button>
              </el-col>
            </el-row>
            <el-row class="name_replay_comment_bottom">
              <el-col :md="{ span: 24 }">
                <span>{{ replyCommentItem.replyComment }} </span>
              </el-col>
            </el-row>
            <el-row
              class="name_replay_comment_bottom"
              v-if="replyCommentItem.replyCommented != null"
            >
              <el-col :md="{ span: 24 }">
                <span>
                  <a href="#" style="text-decoration: none; color: #52a6f7"
                    >{{ replyCommentItem.replyCommented.user.nickname }}：</a
                  >
                  {{ replyCommentItem.replyCommented.replyComment }}
                </span>
              </el-col>
            </el-row>
            <el-row class="name_replay_comment_bottom">
              <el-col :md="{span:4}" :xs="16">
                <PraiseStep :item="replyCommentItem" itemType="comment"></PraiseStep>
              </el-col>
              <el-col :md="{ span: 2 }":xs="6" >
                <button
                  class="blog_operate_botton"
                  @click="handleOpenReply(replyCommentItem, commentItem)"
                >
                  回复
                </button>
              </el-col>
              <el-col
                :md="{ span: 0 }"
                :xs="6"
                v-if="
                  $store.state.user.user.username === replyCommentItem.user.username
                "
              >
                <button
                  class="blog_operate_botton"
                  @click="deleteReplyComment(replyCommentItem.id, index,replyIndex)"
                >
                  删除
                </button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </template>
      <el-drawer
        title="回复评论"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleCloseReply"
      >
        <el-row class="reply_row_bottom" v-if="$store.state.user" :gutter="50">
          <el-col :md="{ span: 1 }">
            <el-avatar
              size="large"
              :src="$store.state.user.user.avatar"
            ></el-avatar>
          </el-col>
          <el-col :md="{ span: 22 }">
            <el-row :gutter="10">
              <el-col :md="{ span: 22 }">
                <el-input
                  type="textarea"
                  :placeholder="'回复：' + replyUserNickname"
                  resize="none"
                  rows="2"
                  v-model="replyCommentJson.replyComment"
                ></el-input>
              </el-col>
              <el-col :md="{ span: 2 }">
                <el-button
                  class="comment_current_button"
                  @click="replyComment('replyLoading')"
                  :loading="loadingObj.replyLoading"
                  >发表</el-button
                >
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-drawer>
    </template>
  </div>
</template>
<script>
import global from '@/api/global_variable.js'
import { createComment, getCommentByBlogApi, praiseApi, stepApi, createReplyCommentApi, deleteCommentApi, deleteReplyCommentApi } from '@/api/comment'
import PraiseStep from '@/components/PraiseStep'
import { parseTime,formatTime } from '@/utils/date'
export default {
  name: 'comment',
  props: ['commentList', 'blogId','blogCreator'],
  components: {
    PraiseStep
  },
  created () {

  },
  data () {
    return {
      loading: true,
      drawer: false,
      direction: 'btt', // 抽屉的打开方式
      praiseInAn: false,
      stepInAn: false,
      imgURL: global.imgURL,
      avatarBaseUrl: global.baseURL,
      replyUserNickname: '',
      commentJson: {
        comment: '',
        blogId: this.blogId,
        blogCreator: this.blogCreator
      },
      replyCommentJson: {
        replyComment: '',
        commentId: '',
        replyCommentId: '',
        blogId:this.blogCreator
      },
      deleteReplyCommentIds: [
        {
          id: ''
        }
      ],
      loadingObj:{}
    }
  },
  methods: {
    formatTime,
    handleOpenReply (replyCommentItem, commentItem) {
      if (this.$store.state.user.user.username !== undefined) {
        if (replyCommentItem != null) {
          this.replyCommentJson.replyCommentId = replyCommentItem.id
          this.replyUserNickname = replyCommentItem.user.nickname
          this.replyCommentJson.replyCommentCreator=replyCommentItem.creator
        } else {
          this.replyUserNickname = commentItem.user.nickname
        }
        this.replyCommentJson.blogId=this.blogId
        this.replyCommentJson.commentId = commentItem.id
        this.replyCommentJson.commentCreator=commentItem.creator
        this.drawer = true
      } else {
        this.$message({
          message: '登录才能评论哦',
          type: 'warning'
        })
      }
    },
    handleCloseReply (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },

    restAnimation (e) {
      e.target.className = 'blog_operate_botton'
    },
    comment (loading) {
      this.$set(this.loadingObj,loading,true)
      this.createComment(loading)
    },
    replyComment () {
      this.createReplyComment()
    },
    async deleteComment (commentId, index) {
      this.commentList.splice(index, 1)
      let ids = [commentId]
      let res = await deleteCommentApi(ids)
      if (res.data.success) {
        this.$message({
          message: '成功删除一条评论',
          type: 'success'
        })
        // this.getComment()
      }
    },
    async deleteReplyComment (replyCommentId,commentIndex,replyCommentIndex) {
      this.commentList[commentIndex].replyCommentList.splice(replyCommentIndex, 1)
      let ids = [replyCommentId]
      let res = await deleteReplyCommentApi(ids)
      if (res.data.success) {
        this.$message({
          message: '成功删除一条评论',
          type: 'success'
        })
        // this.getComment()
      }
    },
    async createReplyComment (loading) {
      this.$set(this.loadingObj,loading,true)
      let res = await createReplyCommentApi(this.replyCommentJson)
      if (res.data.success) {
        this.$message({
          message: '成功回复一条评论，还可以再回复哦',
          type: 'success'
        })
        this.getComment()
      }else{
        this.$message({
          message: res.data.errorMsg,
          type: 'warning'
        })
      }
      this.$set(this.loadingObj,loading,false)
       this.drawer = false
    },
    async createComment (loading) {
      let res = await createComment(this.commentJson)
      if (res.data.success) {
        this.$message({
          message: '成功发表一条评论，还可以再发表哦',
          type: 'success'
        })
        this.getComment()
      }else{
        this.$message({
          message: res.data.errorMsg,
          type: 'warning'
        })
      }
      this.$set(this.loadingObj,loading,false)
    },
    async praise (commentItem, e) {
      if (this.$store.state.user.user.username != undefined) {
        e.target.className = 'blog_operate_botton_animation'
        commentItem.praise += 1
        let query = {
          commentId: commentItem.id,
          username: commentItem.user.username
        }
        let res = await praiseApi(query)
      } else {
        this.$message({
          message: '登录才能操作哦',
          type: 'warning'
        })
      }
    },
    async step (commentItem, e) {
      if (this.$store.state.user.user.username != undefined) {
        e.target.className = 'blog_operate_botton_animation'
        commentItem.step += 1
        let query = {
          commentId: commentItem.id,
          username: commentItem.user.username
        }
        let res = await stepApi(query)
      } else {
        this.$message({
          message: '登录才能操作哦',
          type: 'warning'
        })
      }
    },
    async getComment () {
      let query = {
        blogId: this.blogId
      }
      let conmmentListRes = await getCommentByBlogApi(query)
      if (conmmentListRes.data.success) {
        this.loading = false
        this.commentList = conmmentListRes.data.data
      }
    },
    link_to_user(username){
       this.$router.push({path: '/user', query: {username:username}})
    },
  }
}
</script>

<style lang="less" scoped>
.row_bottom {
  margin-bottom: 15px;
}
.reply_row_bottom {
  margin-bottom: 15px;
  padding-left: 50px;
}
.comment_username {
  font-size: 13px;
  color: #95989b;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60px;
  line-height: 100%;

}
.comment_create_time {
  font-size: 13px;
  color: #95989b;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.reply_comment_username {
  font-size: 13px;
  color: #95989b;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 20px;
  line-height: 20px;
}
.reply_comment_create_time {
  font-size: 13px;
  color: #95989b;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.name_comment_bottom {
  margin-bottom: 5px;
}
.name_replay_comment_bottom {
  margin-bottom: 5px;
}
.gutter {
  padding-left: 10px;
  padding-right: 10px;
}
.comment_current_button {
  height: 54px;
}
.blog_operate_botton {
  font-size: 13px;
  border-radius: 100%;
  border: none;
  color: #969799;
  padding: 0px;
  margin-top: 8px;
  background-color: transparent;
  cursor: pointer;
  height: 18px;
}
.blog_operate_delete_button {
  font-size: 13px;
  border-radius: 100%;
  border: none;
  color: #969799;
  padding: 0px;
  background-color: transparent;
  cursor: pointer;
}
.blog_operate_botton_animation {
  font-size: 13px;
  border-radius: 100%;
  border: none;
  color: #969799;
  padding: 0px;
  margin-top: 8px;
  background-color: transparent;
  cursor: pointer;
  height: 18px;
  animation: showMsg 0.5s;
}

@keyframes showMsg {
  0% {
    font-size: 13px;
  }
  20% {
    font-size: 14px;
    transform: rotate(-9deg);
    -ms-transform: rotate(-9deg); /* IE 9 */
    -moz-transform: rotate(-9deg); /* Firefox */
    -webkit-transform: rotate(-9deg); /* Safari 和 Chrome */
    -o-transform: rotate(-9deg); /* Opera */
  }
  25% {
    font-size: 14px;
    transform: rotate(9deg);
    -ms-transform: rotate(9deg); /* IE 9 */
    -moz-transform: rotate(9deg); /* Firefox */
    -webkit-transform: rotate(9deg); /* Safari 和 Chrome */
    -o-transform: rotate(9deg); /* Opera */
  }

  50% {
    font-size: 15px;
    transform: rotate(-9deg);
    -ms-transform: rotate(-9deg); /* IE 9 */
    -moz-transform: rotate(-9deg); /* Firefox */
    -webkit-transform: rotate(-9deg); /* Safari 和 Chrome */
    -o-transform: rotate(-9deg); /* Opera */
  }
  75% {
    font-size: 15px;
    transform: rotate(9deg);
    -ms-transform: rotate(9deg); /* IE 9 */
    -moz-transform: rotate(9deg); /* Firefox */
    -webkit-transform: rotate(9deg); /* Safari 和 Chrome */
    -o-transform: rotate(9deg); /* Opera */
  }
  100% {
    font-size: 13px;
  }
}
</style>
