<template>
  <div class="details" v-if="blogItem" v-title :data-title="blogItem.heading"
  :style="blogItem.user?{
    background:'url('+blogItem.user.backgroundWall+') no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
  }:''"
  >
    <div style="height: 100px;float:left;width: 100%;" v-if="loading">
      <div v-loading="loading">
      </div>
    </div>
    <el-row v-if="blogItem" style="" :gutter="30">
      <el-col :md="{span:6,offset:1}" :xs="{span:24}">
        <userInfoCard v-if="blogItem.quizFlag!='quiz'":user="blogItem.user" style="margin-bottom: 15px;"></userInfoCard>
        <blogCard style="margin-bottom: 15px;" :blog="blogItem"></blogCard>
        <Hot type="hotBlog" style="margin-bottom: 15px;" @changeDetails="getData"></Hot>
        <Hot type="hotQuiz" style="margin-bottom: 15px;" @changeDetails="getData"></Hot>
      </el-col>
      <el-col :md="{span:15}" style="background-color:#F5F5F5;border-radius: 10px;padding: 20px;">
          <el-row >
            <el-col :md="{span:24}">
              <h1 style="margin: 0px;border-radius: 5px;margin-bottom:15px">
              {{blogItem.heading}}
              </h1>
              <mavon-editor
              v-model="blogItem.blogMd"
              :toolbars="toolbars"
              :ishljs="true"
              :subfield="false"
              defaultOpen="preview"
              :codeStyle="blogItem.codeStyle==null?'atom-one-dark':blogItem.codeStyle"
              style="background-color: #F5F5F5;
              max-height:800px;
              margin-bottom: 15px;
              "
              >
               </mavon-editor>
               <PraiseStep
               style="width: 50%;"
                :item="blogItem" itemType="blog"
                :showGlance="true"
                ></PraiseStep>
            </el-col>
            <el-col v-loading="createSolveLoading" v-if="$store.state.user.user.username!=null &&blogItem.quizFlag==='quiz'" :md="{span:24}"
            style="margin-bottom: 15px;background-color: #F5F5F5;border-radius: 5px;">
              <h2>帮他解答</h2>
              <mavon-editor
              style="
              max-height:740px;
              margin-bottom: 15px;
              "
              ref="mavonEditor"
              @imgAdd="handleEditorImgAdd" @imgDel="imgDel" :toolbars="toolbarsSolve"
                :scrollStyle="true" :ishljs="true" @change="change" v-model="blogContent"
                codeStyle="atom-one-dark"
                />
                <el-button type="primary" @click="createSolve(blogItem.id,blogItem.heading)" style="width: 100%;margin-top: 15px;">
                  解答
                </el-button>
            </el-col>
            <el-col v-if="blogItem.quizFlag==='quiz'" :md="{span:24}"
            style="margin-bottom: 15px;background-color: #F5F5F5;border-radius: 5px;"
            >
              <h2>解答</h2>
              <div v-if="blogItem.solveList.length<=0">
                <h1 style="text-align: center;color: #8D8D8D">暂无解答</h1>
              </div>
              <div v-for="solveItem in blogItem.solveList">
                <div
                @click.stop="link_to_user('/user',solveItem.creator)"
                >
                  <el-row style="cursor: pointer;"

                  >
                    <el-col :md="{span:1}">
                      <el-avatar
                        size="large"
                        :src="solveItem.user.avatar"
                      ></el-avatar>
                    </el-col>
                    <el-col :md="{span:20}">
                      <div>
                        <h4 style="margin:0px;height: 40px;line-height: 40px;margin-left: 5px;">{{solveItem.user.nickname}}</h4>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <mavon-editor
                v-model="solveItem.blogMd"
                :toolbars="toolbars"
                :ishljs="true"
                :subfield="false"
                defaultOpen="preview"
                :codeStyle="blogItem.codeStyle==null?'atom-one-dark':blogItem.codeStyle"
                style="padding:20px;background-color: #F5F5F5;margin-bottom: 15px;
                height: 500px;
                "
                >
                 </mavon-editor>
                 <div style="height: 50px;
                 padding: 5px;
                 ">
                   <PraiseStep
                   style="width: 50%;"
                    :item="solveItem" itemType="blog"></PraiseStep>
                 </div>

              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="{span:24}">
              <comment v-loading="commentLoading"  :commentList="commentList"
              :blogId="blogId" :blogCreator="blogItem.creator"></comment>
            </el-col>
          </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getArticelByIdApi,createSolveApi} from '@/api/article'
  import {getCommentByBlogApi,praiseApi,stepApi} from '@/api/comment'
  import 'mavon-editor/dist/markdown/github-markdown.min.css'
  import blogCard from '@/components/blogCard.vue'
  import Hot from '@/components/Hot.vue'
  import comment from './comment.vue'
  import userInfoCard from './UserInfoCard.vue'
  import global from '@/api/global_variable.js'
  import PraiseStep from '@/components/PraiseStep'
import {
    publishArticleApi,
    getAllLabelApi,
    uploadImageApi,
    getDetailArticelByIdApi,
    updateArticleApi
  } from '@/api/article'
export default {
  components: {
    blogCard,
    comment,
    userInfoCard,
    Hot,
    PraiseStep
  },
  created(){
    this.getData(this.blogId)
  },
  data(){
    return{
      hotQuizList:[
        {
          title:"热门提问top01",
        },{
          title:"热门提问top02",
        },{
          title:"热门提问top03",
        }
      ],
      createSolveLoading:false,
      form:{},
      blogContent:'',
      blogItem:null,
      loading:true,
      commentLoading:true,
      commentList:null,
      blogId:this.$route.query.blogId,
      heading:'',
      imgURL:global.imgURL,
      toolbars:{
        readmodel: true, // 沉浸式阅读
        navigation: true ,// 导航目录
        htmlcode: true, // 展示html源码
      },
      toolbarsSolve: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        help: true, // 帮助
        code: true, // code
        table: true, // 表格
        subfield: true, // 是否需要分栏
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        redo: true, // 下一步
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 1.3.5 */
        undo: true, // 上一步
        trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true // 导航目录
      },
    }
  },
  methods:{
    getData(blogId){
      this.loading=true
      this.blogId=blogId
      // this.blogItem={}
      this.getBlogByBlogId(blogId)
      this.getCommentByBlog()
    },
    async praise(blogItem,e){
      e.target.className='praise_but_selected'
      let query={
        blogId:blogItem.id,
        username:blogItem.user.username,
      }
      blogItem.praise++
      let res=await praiseApi(query)
      e.target.className='praise_but'
    },
    link_to_user(path,username){
       this.$router.push({path: path, query: {username:username}})
    },
    async createSolve(quizId,heading){
      this.createSolveLoading=true
      this.form.quizId=quizId
      this.form.heading=heading
      await createSolveApi(this.form).then((res)=>{
         if(res.data.success){
           this.$message({
             type: 'success',
             message: '成功添加一个解答'
           })
           this.getBlogByBlogId(this.blogId)
         }else{
           this.$message({
             type: 'warning',
             message: res.data.errorMsg
           })
         }
       })
      this.createSolveLoading=false
    },
    handleEditorImgAdd(pos, file) {
      var newdate = new FormData();
      newdate.append("file", file);
      uploadImageApi(newdate).then((res) => { //图片上传到服务器返回图片url 链接
        this.$refs.mavonEditor.$img2Url(pos, this.imgURL + res.data.data.fileName); //通过ref插入到文档中
      });
    },
    imgDel() {

    },
    change() {
      this.form.blog = this.$refs.mavonEditor.d_render
      this.form.blogMd = this.$refs.mavonEditor.d_value
    },
   async getBlogByBlogId(blogId){
     let query={
       blogId:blogId,
     }
      let res= await getArticelByIdApi(query)
      this.blogItem=res.data.data
      if(res.data.success){
        this.heading=this.blogItem.heading
        this.loading=false
      }
    },
    async getCommentByBlog(){
      let query={
        blogId:this.blogId
      }
      let res= await getCommentByBlogApi(query)
      if(res.data.success){
        this.commentList=res.data.data
        this.commentLoading=false
      }
    }
  }
}

</script>

<style lang="less" scoped>
  a{
    text-decoration: none;
  }
  .praise_but{
    width: 100px;text-align: center;
    height: 30px;line-height: 30px;
    border-radius: 30px;
    background-color: #D46A8E;
    cursor: pointer;
    margin-bottom: 15px;
    color: #FFFFFF;
    transition-property: all;
    transition-duration: 0.5s;
  }
  .praise_but_selected{
    width: 100px;text-align: center;
    height: 30px;line-height: 30px;
    border-radius: 30px;
    background-color: #D46A8E;
    cursor: pointer;
    margin-bottom: 15px;
    color: #FFFFFF;
    transition-property: all;
    transition-duration: 0.5s;
    animation: showMsg 0.2s;
  }
  .praise_but:hover{
    background-color: #F78EB2;
  }
  .details{
    background-color: none;
    padding-top: 60px;
  }
  .content_blog{
    padding: 20px;
    margin-top: 100px;
   background-color: #ffffff;
  }
  @keyframes showMsg
  {
    0%   {
      padding: 0px;
    }
    50%  {
       padding: 5px;
      margin-left: -5px;
      margin-top: -5px;
    }
    100% {
      padding: 0px;
    }
  }
</style>
